export const hamburgerMenu = () => {
  const header = document.querySelector('.header');
  const hamburger = document.querySelector('.header__hamburger');
  hamburger.addEventListener('click', () => {
    header.classList.toggle('header--open');
    hamburger.ariaExpanded = header.classList.contains('header--open');
  });
  header.addEventListener('keyup', (e) => {
    if (e.key === 'Escape') {
      header.classList.remove('header--open');
      hamburger.focus();
    }
  });
};