import { hamburgerMenu } from './header';
import { mouseTilt } from './mouse';
import { contactForm } from './contact';
import { cookiePopup } from './cookiePopup';

hamburgerMenu();

document.querySelectorAll('[data-mouse-tilt]').forEach((element) => {
  mouseTilt(element);
});

contactForm();

cookiePopup();