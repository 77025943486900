export const mouseTilt = (element) => {
  const MAX_ROTATE_X = 30;
  const MAX_ROTATE_Y = 30;
  const zTranslation = element.dataset.mouseTilt ?? '0px';
  const trigger = element.dataset.mouseTiltTrigger;
  let isRunning = trigger === 'hover' ? false : true;
  let mouse = { x: undefined, y: undefined };
  let pendingFrameId;

  const frame = () => {
    if (isRunning) {
      const rect = element.getBoundingClientRect();
      const originX = rect.x + (rect.width / 2);
      const originY = rect.y + (rect.height / 2);
      const x = mouse.x - originX;
      const y = mouse.y - originY;
      const rotateX = - (y / window.innerHeight) * MAX_ROTATE_Y;
      const rotateY = (x / window.innerWidth) * MAX_ROTATE_X;
      element.style.transform = `rotateX(${rotateX}deg) rotateY(${rotateY}deg) translate3d(0, 0, ${zTranslation}) perspective(500px)`;
    }
    pendingFrameId = undefined;
  };

  const scheduleFrame = () => {
    if (pendingFrameId) return; // mousemove may occur multiple times each frame
    pendingFrameId = window.requestAnimationFrame(frame);
  };

  const onMouseMove = (e) => {
    mouse.x = e.clientX;
    mouse.y = e.clientY;
    scheduleFrame();
  };
  const onMouseEnter = () => {
    isRunning = true;
    element.style.transition = 'transform 0.1s linear';
  }
  const onMouseLeave = () => {
    element.style.transition = 'transform 0.4s cubic-bezier(0.34, 1.56, 0.64, 1)';
    element.style.transform = '';
    isRunning = false;
  }

  window.addEventListener('mousemove', onMouseMove);
  window.addEventListener('scroll', scheduleFrame);
  element.style.transformStyle = 'preserve-3d';

  if (trigger === 'hover') {
    element.addEventListener('mouseenter', onMouseEnter);
    element.addEventListener('mouseleave', onMouseLeave);
  }

  // Return cleanup function
  return () => {
    window.removeEventListener('mousemove', onMouseMove);
    window.removeEventListener('scroll', scheduleFrame);
    element.removeEventListener('mouseenter', onMouseEnter);
    element.removeEventListener('mouseleave', onMouseLeave);
    element.style.transformStyle = '';
  };
};