const Popup = `
  <div id="cookiePopup" class="sticky bottom-0 w-full flex justify-center items-center gap-2 bg-purple-92 text-">
    <span class="py-2 pl-4">
      We use <a class="text-steelpink hover:underline" href="/cookies">cookies</a> to give you the best experience on our website. If you continue to use this site we will assume that you are happy with their use.
    </span>
    <button class="self-start flex-shrink-0 text-steelpink w-12 h-12 min-w-0">OK</button>
  </div>
`;

export const cookiePopup = () => {
  const isDismissed = localStorage.getItem('isCookiePopupDismissed');
  if (isDismissed) return;
  document.body.appendChild(document.createElement('div')).outerHTML = Popup;
  const popup = document.getElementById('cookiePopup');
  popup.querySelector('button').addEventListener('click', () => {
    popup.remove();
    localStorage.setItem('isCookiePopupDismissed', true);
  });
};
